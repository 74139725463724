import * as React from "react";

import { IParsedFaqTemplate } from "@plum/plum-contentful";

import { IDestinationPageData } from "@/pages/api/d/[destination]";

import { ISearchBookmarksProps } from "@/components/SearchBookmarks";
import { ParsedUrlQuery } from "querystring";

export interface IAppContextState extends IDestinationPageData {
  notFound: boolean;
  languageCode: string;
  currencyId: number;
  searchBookmarks?: ISearchBookmarksProps[];

  //all this props are facet only
  //TODO: move this from root to facet object to differen from home, landing and facet state
  facetName?: string | null;
  facetSlug?: string | null;
  seoBody?: string | null;
  seoLinkText?: string | null;
  noIndex?: boolean;
  searchParams?: string | null;
  faqs?: IParsedFaqTemplate[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  homeOfTheWeek?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  seoLinks?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  similarDestinations?: any;
  query?: ParsedUrlQuery;
}

type IAppContext = IAppContextState;

export const AppContextInitialState: IAppContextState = {
  notFound: false,
  hero: {
    image: "",
    title: "",
    subTitle: "",
  },
  canonical: null,
  listingSections: [],
  featuredArticle: null,
  languageCode: "en",
  currencyId: 1,
  searchDestination: {
    title: "",
    placeId: "",
    polygonId: "",
    type: "",
  },
  latlng: null,
  breadcrumbs: null,
  preview: false,
  searchBookmarks: [],
  seo: null,
  facetName: null,
  facetSlug: null,
  blogPosts: null,
  seoBody: null,
  seoLinkText: null,
  noIndex: false,
  searchParams: null,
  searchListings: [],
  searchTotal: null,
  searchUrl: null,
  homeOfTheWeek: {
    listing: null,
  },
  seoLinks: null,
  similarDestinations: null,
};

export const AppContext = React.createContext<IAppContext>(AppContextInitialState);

AppContext.displayName = "AppContext";

/**
 * Context custom hook
 */
export const useAppContext = () => {
  return React.useContext(AppContext);
};
