import apolloClient from "@/gql/client";
import { GET_SEARCH_RESULTS } from "@/gql/search";
import {
  GetSearchResults,
  GetSearchResultsVariables,
  GetSearchResults_search_defaultResults_results,
  GetSearchResults_search_defaultResults_results_listing,
  GetSearchResults_search_defaultResults_results_dates,
} from "@/gql/types/GetSearchResults";
import { SearchQueryParams, SearchType } from "@/types/graphql-global-types";
import { isString } from "@/utils/search";
import filterObject from "just-filter-object";
import qs from "query-string";
import { EXP_DATELESS_DATED_SEARCH } from "@/components/ProductCardContainer";

const UNSUPPORTED_PARAMETERS = new Set([
  "checkIn",
  "checkOut",
  "flex",

  "flexMonths",
  "flexPeriod",

  "datepickerType",

  "minPrice",
  "maxPrice",
]);

export function getParametersFromSearchUrl(url: string, increaseLimit = 0) {
  let query = qs.parseUrl(url).query;

  if (Object.keys(query).length === 0) {
    query = qs.parse(url);
  }

  const {
    adults,
    children,
    guests,
    location,
    placeId,
    ne_lat,
    ne_lng,
    sw_lat,
    sw_lng,
    limit,
    offset,
    ...staticFilters
  } = filterObject(transformLegacyParams(query), (key: string) => !UNSUPPORTED_PARAMETERS.has(key));

  const hasBounds = !!(ne_lat && ne_lng && sw_lat && sw_lng);
  const hasLocation = !!location;
  const hasPlaceId = !!placeId;

  return {
    guests:
      isString(guests) || isString(adults)
        ? { adults: parseInt(adults as string, 10) || parseInt(guests as string, 10) }
        : null,
    location:
      hasBounds || hasLocation || hasPlaceId
        ? {
            destination: hasPlaceId
              ? { placeId: placeId as string }
              : hasLocation
                ? { searchTerm: location as string }
                : null,
            boundingBox: hasBounds
              ? {
                  ne: { lat: parseFloat(ne_lat as string), lng: parseFloat(ne_lng as string) },
                  sw: { lat: parseFloat(sw_lat as string), lng: parseFloat(sw_lng as string) },
                }
              : null,
          }
        : null,
    staticFilters: transformStaticFiltersToParams(staticFilters),
    page: {
      limit: (limit ? parseInt(limit as string, 10) : 12) + increaseLimit,
      offset: parseInt(offset as string, 10) || 0,
    },
    searchType: SearchType.default,
  };
}

function parseParamToBoolean(param: string): boolean {
  return param ? param.toLowerCase() === "true" : false;
}

function parseStringToIntArray(arg: string): number[] {
  return arg.split(",").map((a) => parseInt(a, 10));
}

function transformStaticFiltersToParams(staticFilters) {
  return {
    infantsAllowed: parseParamToBoolean(staticFilters.infantsAllowed) || null,
    childrenAllowed: parseParamToBoolean(staticFilters.childrenAllowed) || null,
    petsAllowed: parseParamToBoolean(staticFilters.petsAllowed) || null,
    instantBook: parseParamToBoolean(staticFilters.instantBook) || null,
    freeCancellation: parseParamToBoolean(staticFilters.freeCancellation) || null,
    stepFreeAccess: parseParamToBoolean(staticFilters.stepFreeAccess) || null,
    maxSteps: staticFilters.maxSteps || null,
    amenities:
      staticFilters.amenities?.length > 0 ? parseStringToIntArray(staticFilters.amenities) : null,
    occasions:
      staticFilters.occasions?.length > 0 ? parseStringToIntArray(staticFilters.occasions) : null,
    propertyTypes:
      staticFilters.propertyTypes?.length > 0
        ? parseStringToIntArray(staticFilters.propertyTypes)
        : null,
    minBeds: staticFilters.minBeds ? parseInt(staticFilters.minBeds, 10) : null,
    minBedrooms: staticFilters.minBedrooms ? parseInt(staticFilters.minBedrooms, 10) : null,
    minBathrooms: staticFilters.minBathrooms ? parseInt(staticFilters.minBathrooms, 10) : null,
  };
}

/**
 * Repurposed from `search-ssr` repo
 **/

export function transformLegacyParams({
  Guests,
  CheckIn,
  CheckOut,
  FlexibleDates,
  PlaceId,
  Location,
  Bedrooms,
  Bathrooms,
  InfantsAllowed,
  ChildrenAllowed,
  Amenities,
  IsInstantBook,
  HasFlexibleCancellationPolicy,
  Occasion,
  PropertyTypes,
  MaxSteps,
  ...query
}: qs.ParsedQuery): qs.ParsedQuery {
  const queryObj: qs.ParsedQuery = {
    guests: Guests,

    checkIn: CheckIn,
    checkOut: CheckOut,
    flexCheckIn: FlexibleDates && "3",

    placeId: PlaceId,
    location: Location,

    minBedrooms: Bedrooms,
    minBathrooms: Bathrooms,

    infantsAllowed: InfantsAllowed,
    childrenAllowed: ChildrenAllowed,

    instantBook: IsInstantBook,
    freeCancellation: HasFlexibleCancellationPolicy,

    occasions: Occasion,
    propertyTypes: PropertyTypes,

    amenities: Amenities && transformLegacyAmenities(Amenities),

    minPrice: query.PriceBottom,
    maxPrice: query.PriceTop,

    ...query,
  };

  if ((Amenities as string[])?.includes("28")) {
    queryObj.petsAllowed = "true";
  }

  if (MaxSteps === "0") {
    queryObj.stepFreeAccess = "true";
  }

  return queryObj;
}

function transformLegacyAmenities(amenities: string | (null | string)[]) {
  return Array.isArray(amenities)
    ? amenities
    : amenities
        ?.split(",")
        .map((i) => legacyAmenitiesToNew[parseInt(i)])
        .join(",");
}

const legacyAmenitiesToNew = {
  2: 1,
  3: 2,
  28: 38,
  42: 62,
  44: 61,
  39: 55,
  40: 56,
  41: 57,
  29: 40,
  35: 50,
  36: 51,
  32: 46,
  34: 48,
  45: 64,
  18: 23,
  20: 25,
  43: 59,
  12: 16,
  13: 19,
};

type SearchResult = GetSearchResults_search_defaultResults_results;
type PricingProps = {
  nightlyPrice: SearchResult["nightlyPrice"];
  discountPercentage: SearchResult["discountPercentage"];
  totalPriceWithoutDiscount: SearchResult["totalPriceWithoutDiscount"];
  nightlyPriceWithoutDiscount: SearchResult["nightlyPriceWithoutDiscount"];
  totalPrice: SearchResult["totalPrice"];
  dates: GetSearchResults_search_defaultResults_results_dates;
};

export type ListingWithPrice = Partial<GetSearchResults_search_defaultResults_results_listing> &
  PricingProps;

export async function getSearchResults(parameters: SearchQueryParams, alternatePlaceId?: string) {
  try {
    const searchQuery = await apolloClient.query<GetSearchResults, GetSearchResultsVariables>({
      query: GET_SEARCH_RESULTS,
      variables: {
        parameters,
      },
      context: {
        headers: {
          cookie: EXP_DATELESS_DATED_SEARCH ? "exp_search_dateless_dated=test;" : "",
        },
      },
      fetchPolicy: "no-cache",
    });

    let listings = [
      ...(searchQuery.data?.search?.defaultResults?.results || []),
      ...(searchQuery.data.search?.expandedResults?.results || []).filter(Boolean),
    ]?.map((result) => {
      return {
        ...result?.listing,
        nightlyPrice: result?.nightlyPrice,
        discountPercentage: result?.discountPercentage,
        totalPriceWithoutDiscount: result?.totalPriceWithoutDiscount,
        nightlyPriceWithoutDiscount: result?.nightlyPriceWithoutDiscount,
        totalPrice: result?.totalPrice,
        dates: result?.dates,
      };
    }) as ListingWithPrice[];

    const total =
      (searchQuery.data?.search?.pagination?.total || 0) +
      (searchQuery.data.search?.expandedResults?.total || 0);

    if (total <= 8 && alternatePlaceId) {
      const searchQuery = await apolloClient.query<GetSearchResults, GetSearchResultsVariables>({
        query: GET_SEARCH_RESULTS,
        variables: {
          parameters: { ...parameters, location: { destination: { placeId: alternatePlaceId } } },
        },
        context: {
          headers: {
            cookie: EXP_DATELESS_DATED_SEARCH ? "exp_search_dateless_dated=test;" : "",
          },
        },
        fetchPolicy: "no-cache",
      });
      const alternateListings = searchQuery.data?.search?.defaultResults?.results?.map((result) => {
        return {
          ...result?.listing,
          nightlyPrice: result?.nightlyPrice,
          discountPercentage: result?.discountPercentage,
          totalPriceWithoutDiscount: result?.totalPriceWithoutDiscount,
          nightlyPriceWithoutDiscount: result?.nightlyPriceWithoutDiscount,
          totalPrice: result?.totalPrice,
          dates: result?.dates,
        };
      }) as ListingWithPrice[];
      listings = [...listings, ...alternateListings];
    }
    return {
      listings,
      total,
    };
  } catch (error) {
    console.info(error);
    console.error("An error has occurred while fetching search results");

    return {
      listings: [],
      total: 0,
    };
  }
}
