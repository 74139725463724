import { memo, useCallback } from "react";
import { parseCookies } from "nookies";

import { FormProvider, useForm } from "react-hook-form";
import urljoin from "url-join";
import qs from "query-string";

import { env } from "@/env.mjs";

import { DateFormats, formatDate } from "@/utils/dates";

import { useRecentLocations } from "@/hooks/useLocalStorage";

import { DatepickerType, DatesState, DestinationState, GuestsState } from "@/types/search";

import { useAppContext } from "@/data/AppProvider";

import { MobileFilters } from "./MobileFilters";
import { DesktopFilters } from "./DesktopFilters";

import { applyParamRestrictions } from "@/utils/search";

export interface IPrimaryFiltersFormValues {
  datepickerType: DatepickerType | null;
  dates: DatesState;
  guests: GuestsState;
  destination: DestinationState;
}

export const PrimaryFiltersForm = memo(() => {
  const { canonical, searchParams, facetSlug } = useAppContext();
  const cookies = parseCookies();

  const methods = useForm<IPrimaryFiltersFormValues>({
    defaultValues: {
      dates: {
        calendar: {
          checkIn: undefined,
          checkOut: undefined,
          flex: null,
        },
        flexible: {
          period: null,
          months: [],
        },
      },
      destination: {
        location: canonical?.name || undefined,
        placeId: canonical?.placeId || undefined,
      },
      guests: {
        adults: undefined,
        children: undefined,
        infants: undefined,
        pets: undefined,
      },
      datepickerType: "calendar",
    },
  });

  const [, setRecentLocation] = useRecentLocations();

  const onSubmit = useCallback(
    (data: IPrimaryFiltersFormValues) => {
      const facetSlugForNewFacets = canonical?.slug?.split("/")[1];
      if (data?.destination?.location) {
        setRecentLocation({
          label: data?.destination?.location,
          value: data?.destination?.placeId,
        });
      }

      const shouldAddDatepickerType =
        data.datepickerType === "calendar"
          ? data.dates.calendar.checkIn && data.dates.calendar.checkOut
          : data.dates.flexible.period && data.dates.flexible.months.length > 0;

      const formattedParams = applyParamRestrictions(
        searchParams,
        facetSlug || facetSlugForNewFacets
      );

      const url = qs.stringifyUrl(
        {
          url: urljoin(env.NEXT_PUBLIC_BASE_URL, "search"),
          query: {
            ...qs.parse(formattedParams || ""),
            checkIn: data.dates.calendar.checkIn
              ? formatDate(data.dates.calendar.checkIn, DateFormats.ISO)
              : null,
            checkOut: data.dates.calendar.checkOut
              ? formatDate(data.dates.calendar.checkOut, DateFormats.ISO)
              : null,
            flexCheckIn: data.dates.calendar.flex,
            flexPeriod: data.dates.flexible.period,
            flexMonths: data.dates.flexible.months.toString() || null,
            datepickerType: shouldAddDatepickerType ? data.datepickerType : null,
            location: data.destination.location,
            placeId: data.destination.placeId, // TODO: further discuss why we would want to have the placeId from the canonical instead of this.
            adults: data.guests.adults || null,
            children: data.guests.children || null,
            infants: data.guests.infants || null,
            pets: data.guests.pets || null,
          },
        },
        {
          skipNull: true,
          skipEmptyString: true,
        }
      );

      window.location.href = url;
    },
    [canonical, searchParams, setRecentLocation, applyParamRestrictions, cookies]
  );

  return (
    <FormProvider {...methods}>
      <form className="sm:h-full" id="primaryFiltersForm" onSubmit={methods.handleSubmit(onSubmit)}>
        <div className="h-full w-full max-md:hidden">
          <DesktopFilters />
        </div>
        <div className="h-full w-full md:hidden">
          <MobileFilters />
        </div>
      </form>
    </FormProvider>
  );
});

PrimaryFiltersForm.displayName = "PrimaryFiltersForm";
